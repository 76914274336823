<template>
  <!-- 
    Used to copy the given data into the clipboard.
   -->
  <span
    v-tooltip="$t('copyToClipboard')"
    class="copyToClipboard"
    @click="copyToClipboard(copyData, $event)"
  >
    <font-awesome-icon
      v-show="!hide"
      class="mr-1"
      icon="copy"
    />
    <small v-if="small">{{ copyData }}</small>
    <template v-else>{{ copyData }}</template>
  </span>
</template>

<script>
export default {
  name: "CopyToClipboard",
  props: {
    copyData: {
      type: String,
      required: true
    },
    small: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    hide: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    }
  },
  methods: {
    // copies the specified data to the clipboard of the user
    copyToClipboard (data, event) {
      let target = event.target;
      if(target.nodeName == "svg") {
        return;
      }
      let targetClass = event.target.getAttribute('class');
      targetClass = 'copyToClipboard animated';
      target.setAttribute('class', targetClass);
      setTimeout(() => {
        targetClass = 'copyToClipboard';
        target.setAttribute('class', targetClass);
      }, 333);
      // adds an element to hold the data
      let element = document.createElement('textarea');
      // passes the data to the newly created element
      element.value = data;
      element.setAttribute('readonly', '');
      element.className = 'offScreen';
      // adds the element to the website (offscreen)
      document.body.appendChild(element);
      element.select();
      // copies the content of the element to the clipboard
      document.execCommand('copy');
      // removes the created element now that it's no longer of use
      document.body.removeChild(element);
      // inform the user about the success of the function
      this.$snotify.success("The data has been copied to your clipboard.");
    }
  }
}
</script>

<style scoped>
.copyToClipboard,
.copyToClipboard i {
  cursor: pointer;
  font-size: 1rem;
}
.copyToClipboard:hover,
.copyToClipboard:hover i::before {
  color: #177ba8;
  font-size: 1rem;
}
.copyToClipboard.animated {
  animation-name: bounce;
  animation-duration: .333s;
  font-size: 1rem;
}
@keyframes bounce {
  0% {font-size: 1rem;}
  50% {font-size: 0.5rem;}
  100% {font-size: 1rem;}
}
</style>
